import React from 'react'
import Footer from '../footer/Footer'
import TransitionPage from '../TransitionPage'
import './CalendarStyles.css'

const About = () => {
    return (
        <>
            <TransitionPage>
                <div className='calendar'>
                    <h1 className='title'>Calendar</h1>
                    <div className='calendar-container'>
                        <iframe src="https://embed.styledcalendar.com/#ksqPCjW3pEFuZsZrpqzP" title="Styled Calendar" className="styled-calendar-container" data-cy="calendar-embed-iframe"></iframe>
                        <script async type="module" src="https://embed.styledcalendar.com/assets/parent-window.js"></script>
                    </div>

                </div>
                <Footer />
            </TransitionPage>
        </>
    )
}

export default About
