import React from "react";
import Modal from "../modal/Modal";
import { FaMapMarkerAlt, FaChevronRight } from "react-icons/fa";

const LocationModal = ({ isModalOpen, setIsModalOpen }) => {
  return (
    <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
      <h3>
        <FaMapMarkerAlt />
        Our Locations
      </h3>
      <ul className="modal-list">
        <li className="modal-item">
          <div className="location-info">
            <h4>
              <span>Honolulu</span>{" "}
              <a href="https://www.keikikingdom.com/" target="_blank" rel="noreferrer">
                Visit Site <FaChevronRight />
              </a>
            </h4>
            <p>
              <strong>Address:</strong> 1460 S Beretania St. Honolulu, HI 96814
            </p>
            <p>
              <strong>Contact Us:</strong> <a href="tel:8085153454">(808) 515-3454</a>
            </p>
          </div>
          <ul className="modal-cta">
            <li>
              <button>
                <a
                  href="https://keikikingdom.booknow.software/kiosk/s/?site=a0IDo000000E2XhMAK"
                  target="_blank"
                  rel="noopener noreferrer">
                  Book Now
                </a>
              </button>
            </li>
            <li>
              <button>
                <a
                  href="https://keikikingdom.booknow.software/bnow__newwaiver"
                  target="_blank"
                  rel="noopener noreferrer">
                  Sign Waiver
                </a>
              </button>
            </li>
          </ul>
        </li>
        <li className="modal-item">
          <div className="location-info">
            <h4>
              <span>Pearlridge</span>
            </h4>
            <p>
              <strong>Address:</strong> 98-145 Kaonohi St, Aiea, HI 96701
            </p>
            <p>
              <strong>Contact Us:</strong> <a href="tel:8082075888">(808) 207-5888</a>
            </p>
          </div>
          <ul className="modal-cta">
            <li>
              <button>
                <a href="https://keikikingdom.aluvii.com/store/shop/categoryproducts?id=1&offSet=480&categoryId=1" target="_blank" rel="noopener noreferrer">
                  Book Now
                </a>
              </button>
            </li>
            <li>
              <button>
                <a
                  href="https://keikikingdom.aluvii.com/employee/Waiver/SignWaiver2?waiverId=1"
                  target="_blank"
                  rel="noopener noreferrer">
                  Sign Waiver
                </a>
              </button>
            </li>
          </ul>
        </li>
      </ul>
    </Modal>
  );
};

export default LocationModal;
