import ballpit from "../assets/activties/ball-pit.webp";
import sandbox from "../assets/activties/sandbox.webp";
import carousel from "../assets/activties/carousel.webp";
import trampoline from "../assets/activties/trampoline.webp";
import slides from "../assets/activties/slides.webp";
import dropslide from "../assets/activties/drop-slide.webp";
import roleplay from "../assets/activties/roleplay.webp";
import zipline from "../assets/activties/zipline.webp";
import interactiveWalls from "../assets/activties/interactive-walls.webp";
import superRunio from "../assets/activties/super-runio.webp";
import ninjaWarriorCourse from "../assets/activties/ninja-warrior-course.webp";
import jungleGym from "../assets/activties/jungle-gym.webp";
import rockclimb from "../assets/activties/rock-climb.webp";
import pigride from "../assets/activties/pig-ride.webp";
import ballcatchgames from "../assets/activties/ball-catch-game.webp";

export const activity = [
  {
    title: "Ball Pit",
    image: ballpit,
  },
  {
    title: "SandBox",
    image: sandbox,
  },
  {
    title: "Carousel",
    image: carousel,
  },
  {
    title: "Trampoline",
    image: trampoline,
  },
  {
    title: "Slides",
    image: slides,
  },
  {
    title: "Drop Slide",
    image: dropslide,
  },
  {
    title: "Roleplay",
    image: roleplay,
  },
  {
    title: "Ziplining",
    image: zipline,
  },
  {
    title: "Interactive Walls",
    image: interactiveWalls,
  },
  {
    title: "Super Runio",
    image: superRunio,
  },
  {
    title: "Ninja Warrior Course",
    image: ninjaWarriorCourse,
  },
  {
    title: "Jungle Gym",
    image: jungleGym,
  },
  {
    title: "Rock Climb",
    image: rockclimb,
  },
  {
    title: "Pig Ride",
    image: pigride,
  },
  {
    title: "Ball Catch Games",
    image: ballcatchgames,
  },
];
