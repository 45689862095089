import React from "react";
import Footer from "../footer/Footer";
import TransitionPage from "../TransitionPage";
import { FaArrowDown } from "react-icons/fa";
import "./PriceStyles.css";
import { Link } from "react-router-dom";
import firstFloorImage from "../../assets/prices/first-floor-party-room.jpg";
import secondFloorImage from "../../assets/prices/second-floor-party-room.jpg";
import partyRoomIncludesImage from "../../assets/prices/party-room-includes.jpg";

const Price = () => {
  const [activeButton, setActiveButton] = React.useState("first-floor");

  return (
    <>
      <TransitionPage>
        <div className="price">
          <div id="price-header">
            <h1 className="title">Prices</h1>
            <div className="price-desc">
              <h2>
                If you have any questions please check out the{" "}
                <Link to="/rules">
                  <span className="link-blue">rules</span>
                </Link>{" "}
                or{" "}
                <Link to="/contact">
                  <span className="link-blue">contact</span>
                </Link>{" "}
                us!
              </h2>
              <h3 className="buyout">Want to rent out Keiki Kingdom for your next celebration?</h3>
              <h3 className="buyout">
                Please{" "}
                <Link to="/contact">
                  <span className="link-blue">contact</span>
                </Link>{" "}
                us for buy-out events!
              </h3>
            </div>
            <div className="container">
              <h2>
                <FaArrowDown className="arrow-down" /> Check Prices Below <FaArrowDown className="arrow-down" />
              </h2>
              <div className="media-arrow">
                <FaArrowDown className="arrow-down" /> <FaArrowDown className="arrow-down" />
              </div>
              <div className="content">
                <button onClick={() => window.location.replace("/price#admission")}>Admission</button>
                <button onClick={() => window.location.replace("/price#party")}>Party Rooms</button>
              </div>
            </div>
          </div>

          <div id="admission">
            <div className="container">
              <h1 className="title">General Admissions</h1>
              <h3>Parents must accompany their children!</h3>
              <p className="notice">
                Membership and access are not available during private events and maintenance days
                <br />
                Please check our{" "}
                <Link to="/calendar">
                  <span className="link-blue">calendar</span>
                </Link>{" "}
                for updates!
              </p>
              <div className="content">
                <table>
                  <thead>
                    <tr>
                      <th></th>
                      <th>CHILDREN (12 Month - Age 17)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="td1">
                        <strong>2 Hours</strong>
                      </td>
                      <td>$26</td>
                    </tr>
                    <tr>
                      <td className="td1">
                        <strong>3 Hours</strong>
                      </td>
                      <td>$36</td>
                    </tr>
                    <tr>
                      <td className="td1">
                        <strong>Extension Fee</strong>
                      </td>
                      <td>$10/Hour</td>
                    </tr>
                  </tbody>
                </table>
                <table>
                  <thead>
                    <tr>
                      <th></th>
                      <th>PARENTS/GUARDIANS (Limited to 2 adult Per Child)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="td1">
                        <strong>2 Hours</strong>
                      </td>
                      <td>$8</td>
                    </tr>
                    <tr>
                      <td className="td1">
                        <strong>3 Hours</strong>
                      </td>
                      <td>$13</td>
                    </tr>
                    <tr>
                      <td className="td1">
                        <strong>Extension Fee</strong>
                      </td>
                      <td>$5/Hour</td>
                    </tr>
                  </tbody>
                </table>
                <table>
                  <thead>
                    <tr>
                      <th></th>
                      <th>OTHERS</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="td1">
                        <strong>Keiki Kingdom Grip Socks</strong>
                      </td>
                      <td>$3</td>
                    </tr>
                    <tr>
                      <td className="td1">
                        <strong>Keiki Carnival Ride</strong>
                      </td>
                      <td>$3</td>
                    </tr>
                  </tbody>
                </table>
                <div className="details">
                  <p>
                    <strong className="info">Please Note:</strong>{" "}
                  </p>
                  <p>Children: 12 months to 17 years old</p>
                  <p>Infant must remain in a safety carrier accompanied by an adult AT ALL TIMES</p>
                  <p>Teens 14 years and above are not allowed to jump or play on certain equipment.</p>
                  <p className="miniDetails">
                    Reason for this is because the equipment is designed for younger children and have weight or height
                    restrictions to ensure safety.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <hr />

          <div id="party">
            <div className="container">
              <h1 className="title">Reserve a Party Room!</h1>
              <p>
                Come book a private party room! It's the perfect place to celebrate birthdays and events for your
                children!
              </p>
              <div className="party-room-btn-content">
                <button
                  className={activeButton === "first-floor" ? "active" : ""}
                  onClick={() => setActiveButton("first-floor")}>
                  First Floor
                </button>
                <button
                  className={activeButton === "second-floor" ? "active" : ""}
                  onClick={() => setActiveButton("second-floor")}>
                  Second Floor
                </button>
              </div>
              <div className="price-content">
                {activeButton === "first-floor" ? <><img src={firstFloorImage} alt="First Floor Party Room" /> <img src={partyRoomIncludesImage} alt="Floor Party Room Includes" /></>: null}
                {activeButton === "second-floor" ? <><img src={secondFloorImage} alt="Second Floor Party Room" /></> : null}
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </TransitionPage>
    </>
  );
};

export default Price;
