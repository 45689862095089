// import React, { useRef, useState } from "react";
import Footer from "../footer/Footer";
import TransitionPage from "../TransitionPage";
// import bgVideo from "../../assets/background/hero-video.mp4";
import "./RulesStyles.css";
import useCollapse from "react-collapsed";
import { FaPlus, FaMinus } from "react-icons/fa";
import { rule } from "../../data/rule";

function Section(props) {
  const config = {
    // defaultExpanded: props.defaultExpanded || false,
    // collapsedHeight: props.collapsedHeight || 0
    duration: 200,
  };
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse(config);
  return (
    <div className="collapsible">
      <div
        className="collapse-header"
        style={isExpanded ? { backgroundColor: "var(--primary-color)" } : { backgroundColor: "white" }}
        {...getToggleProps()}>
        <div className="collapse-icon">{isExpanded ? <FaMinus /> : <FaPlus />}</div>
        <div className="collapse-title">
          <h3>{props.title}</h3>
        </div>
      </div>
      <div {...getCollapseProps()}>
        <div className="collapse-content">{props.children}</div>
      </div>
    </div>
  );
}

const Rules = () => {
  // const rulesVideoRef = useRef(null);
  // const [hideVideoOverlay, setHideVideoOverlay] = useState(false);

  // const handleVideoPlayClick = () => {
  //   setHideVideoOverlay(true);
  //   rulesVideoRef.current.play();
  // };

  return (
    <>
      <TransitionPage>
        <div className="rules">
          <div className="w wave"></div>
          <div className="w wave2"></div>
          <div className="w wave3"></div>
          <div className="w wave4"></div>
          <div className="rules-desc">
            <h1 className="title">Rules</h1>
          </div>
          {/* <div className="rules-video">
            <div className={`video-overlay ${hideVideoOverlay ? "hide" : ""}`} onClick={handleVideoPlayClick}>
              <h3>Trampoline Park Safety Video</h3>
              <div className="play-icon">
                <FaPlayCircle />
              </div>
            </div>
            <video ref={rulesVideoRef} className="bg-video" playsInline controls id="myVideo">
              <source src={bgVideo} type="video/mp4" />
            </video>
          </div> */}
          <div className="content">
            {rule.map((item, idx) => (
              <Section key={idx} title={item.title}>
                <p>{item.description}</p>
              </Section>
            ))}
          </div>
        </div>
        <Footer />
      </TransitionPage>
    </>
  );
};

export default Rules;
