import React from "react";
import "./HomeStyles.css";
import "./AboutStyles.css";
import { FaMapSigns } from "react-icons/fa";

import mobileCharacter from "../../assets/character/mobile-character.png";
import desktopCharacter from "../../assets/character/desktop-character.png";
import TransitionPage from "../TransitionPage";
import { activity } from "../../data/activity";
import facility from "../../assets/about/facility.webp";
import mission from "../../assets/about/mission.webp";
import inclusion from "../../assets/about/inclusion.webp";
import bgVideo from "../../assets/background/hero-video.mp4";
import Footer from "../footer/Footer";

const Home = ({ setIsModalOpen }) => {
  return (
    <>
      <TransitionPage>
        <button onClick={() => setIsModalOpen(true)} className="location-cta">
          <span>Our Locations</span>
          <FaMapSigns />
        </button>
        <div className="home">
          <div className="parallax-video">
            <video className="bg-video" playsInline autoPlay muted loop id="myVideo">
              <source src={bgVideo} type="video/mp4" />
            </video>
          </div>
          <div className="image">
            <img className="mobile-character" src={mobileCharacter} alt="keikikngdom character" />
            <img className="desktop-character" src={desktopCharacter} alt="keikikngdom character" />
            <div className="ocean">
              <div className="wave"></div>
              <div className="wave"></div>
            </div>
          </div>
          <div className="content">
            <h1 className="static-text">
              <span className="orange">K</span>
              <span className="blue">E</span>
              <span className="green">I</span>
              <span className="orange">K</span>
              <span className="green space-right">I</span>
              <br className="hide-desktop" />
              <span className="orange">K</span>
              <span className="green">I</span>
              <span className="yellow">N</span>
              <span className="pink">G</span>
              <span className="teal">D</span>
              <span className="lightpink">O</span>
              <span className="hotpink">M</span>
            </h1>

            <p className="bouncing-text">
              <span className="one blue">W</span>
              <span className="two teal">o</span>
              <span className="three orange">n</span>
              <span className="four hotpink">d</span>
              <span className="five yellow">e</span>
              <span className="six blue">r</span>
              <span className="seven teal">l</span>
              <span className="eight hotpink">a</span>
              <span className="nine orange">n</span>
              <span className="ten blue space-right-sub">d</span>
              <br className="hide-desktop" />
              <span className="eleven teal">A</span>
              <span className="twelve orange">d</span>
              <span className="thirteen hotpink">v</span>
              <span className="fourteen yellow">e</span>
              <span className="fifteen blue">n</span>
              <span className="sixteen teal">t</span>
              <span className="seventeen green">u</span>
              <span className="eighteen orange">r</span>
              <span className="nineteen green">e</span>
            </p>
            <button onClick={() => window.location.replace("#about")}>Learn More</button>

            <div className="scroll-down" onClick={() => window.location.replace("#about")}>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM7.40004 8.55004C7.64857 8.21867 8.11867 8.15152 8.45004 8.40004L11.25 10.5C11.6945 10.8334 12.3056 10.8334 12.75 10.5L15.55 8.40004C15.8814 8.15152 16.3515 8.21867 16.6 8.55004C16.8486 8.88141 16.7814 9.35152 16.45 9.60004L13.65 11.7C12.6723 12.4334 11.3278 12.4334 10.35 11.7L7.55004 9.60004C7.21867 9.35152 7.15152 8.88141 7.40004 8.55004ZM8.45004 13.4C8.11867 13.1515 7.64857 13.2187 7.40004 13.55C7.15152 13.8814 7.21867 14.3515 7.55004 14.6L10.35 16.7C11.3278 17.4334 12.6723 17.4334 13.65 16.7L16.45 14.6C16.7814 14.3515 16.8486 13.8814 16.6 13.55C16.3515 13.2187 15.8814 13.1515 15.55 13.4L12.75 15.5C12.3056 15.8334 11.6945 15.8334 11.25 15.5L8.45004 13.4Z"
                  fill="#28303F"
                />
              </svg>
            </div>
          </div>
        </div>
        <div className="about" id="about">
          <h1 className="title">About Us</h1>
          <div className="container">
            <img src={mission} alt="mission" />
            <div className="content mission">
              <h3>We Play to Remember</h3>
              <h4>Our Mission</h4>
              <p>
                Here at Keiki Kingdom, we believe that Play is a way for children to use their creativity while developing their imagination,
                dexterity, and physical strength. Building a brighter and fun future for the children of tomorrow is why
                we set out to construct exciting playgrounds that will impress even you.
              </p>
            </div>
          </div>

          <div className="container container-facility">
            <div className="content facility">
              <h3>What We Offer</h3>
              <h4>Our Facility</h4>
              <p>
                Keiki Kingdom is the biggest indoor playground in Hawaii offering a multi-level play structure, separate
                area for younger children, party rooms, slides, climbing walls, ball pits, sand box, and much more. It
                is fully air-conditioned and supervised by trained staff to keep your kids safe and happy.
              </p>
            </div>
            <img src={facility} alt="our facility" />
          </div>

          <div className="container">
            <img src={inclusion} alt="inclusion" />
            <div className="content mission">
              <h3>Play Areas For All Kids</h3>
              <h4>Fun For All</h4>
              <p>
                Our indoor playground is for children 17 years old and below. However, we have a special gated
                toddler section with fun activities specifically designed for them. The toddler area is isolated quite
                nicely so older kids aren’t in the way of your small infant/toddler as they crawl and explore.
              </p>
            </div>
          </div>
        </div>

        <div id="team" className="team">
          <div className="container">
            <h1 className="title">Activities</h1>
            <div className="main-content">
              {activity.map((activity, idx) => (
                <div key={idx} className="card-container">
                  <div className="card">
                    <div className="content">
                      <div className="imgBox">
                        <img src={activity.image} alt={activity.title} />
                      </div>

                      <div className="contentBox">
                        <h2>{activity.title}</h2>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <Footer />
      </TransitionPage>
    </>
  );
};

export default Home;
